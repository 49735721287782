<script setup>
const app = useNuxtApp()
const themeStore = useThemeStore()

const IS_SSR = app.ssrContext
const domain = IS_SSR ? app.ssrContext.event?.node?.req?.headers?.host : window.location.host
const {data} = await useAsyncData('theme', () => themeStore.getThemeData(domain))
const themeData = data.value

const themeJson = themeData?.theme_json
const baseUrl = themeData?.base_url
const socialIcons = themeJson?.social_icons || []
const iconsMap = themeJson?.assets.social_icons

const socialCustomIcons = computed(() => {
  const modifiedIcons = socialIcons?.map((icon) => {
    const iconPath = iconsMap[`${icon.name}`]
    return {
      custom_css_class: icon.custom_css_class,
      icon_class: icon.icon_class,
      icon_url: iconPath ? `${baseUrl}${themeJson.asset_base_path}${iconPath}` : '',
      target_url: icon.target_url,
    }
  })
  return modifiedIcons || []
})
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-social-links.css';
</style>

<template>
  <ul class="nu-social-links">
    <li
      class="social-item"
      :key="index"
      v-for="(social, index) in socialCustomIcons"
    >
      <a
        :class="social?.custom_css_class ? social.custom_css_class : null"
        :data-testid="`${social?.icon_class}-social-url`"
        :href="social?.target_url"
        target="_blank"
      >
        <img
          :alt="social?.icon_class"
          :data-testid="`${social?.icon_class}-custom-icon`"
          :src="social?.icon_url"
          v-if="social?.icon_url"
        />
        <font-awesome-icon
          :alt="social?.icon_class"
          :data-testid="`${social?.icon_class}-fa-icon`"
          :icon="'fa-brands fa-' + social?.icon_class"
          v-else
        />
      </a>
    </li>
  </ul>
</template>
